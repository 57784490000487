import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import BlockContent from '../components/block-content';
import Card from '../components/card';

export default function Artist({ data }) {
  const lf = new Intl.ListFormat('en-AU');
  const df = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const programs = data.programs.nodes.filter((program) => program.people.some((person) => person.id === data.person.id));

  function inRange(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    return startDate < new Date() && endDate > new Date();
  }

  function getDateRange(startDate, endDate) {
    const startParts = df.formatToParts(new Date(startDate));
    const endParts = df.formatToParts(new Date(endDate));

    return `${type('day', startParts)} ${type('month', startParts)} → ${type('day', endParts)} ${type('month', endParts)} ${type('year', endParts)}`;

    function type(type, array) {
      return array.find((item) => item.type === type).value;
    }
  }

  return (
    <>
      <div
        className="type--50"
        css={css`
          background-color: var(--orange);
          padding: var(--margin);

          > a {
            padding-top: 45px;
            border-top: var(--borderWidth) solid var(--white);

            &:first-of-type {
              padding-top: 0;
              border-top: none;
            }
          }
        `}
      >
        {data.person._rawDescription && <div
          css={css`
            padding-bottom: 55px;
            border-bottom: var(--borderWidth) solid var(--white);
            margin-bottom: 55px;

            @media (max-width: 512px) {
              margin-bottom: 30px;
            }

            strong {
              font-weight: normal;
            }
          `}
        >
          <BlockContent
            className="type--35 type--west-space--important"
            content={data.person._rawDescription}
          />
        </div>}
        {programs.length > 0 && programs
          .map((item, i) => <Card
            key={i}
            url={`/program/${item.slug.current}`}
            image={item.featuredImage}
            title={<>
              &ldquo;{item.title}&rdquo;<br />
              {item.people.length > 0 && lf.format(item.people.map((person) => person.name))}<br />
              {getDateRange(item.startDate, item.endDate)}
            </>}
            oppositeFrame
          />)
        }
        {programs.length <= 0 && <>No programs.</>}
      </div>
    </>
  );
}

export const query = graphql`
  query ArtistTemplateQuery($slug: String!) {
    person: sanityPerson(slug: { current: { eq: $slug } }) {
      id
      _rawDescription(resolveReferences: { maxDepth: 10 })
    }
    programs: allSanityProgram(
      filter: {people: {elemMatch: {slug: {current: {eq: $slug}}}}}
      sort: {fields: startDate, order: ASC}
    ) {
      nodes {
        title
        slug {
          current
        }
        startDate
        endDate
        featuredImage {
          ...Image
        }
        people {
          id
          name
        }
      }
    }
  }
`;
